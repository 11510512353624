.Forth {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: rgb(31, 54, 86);
  padding: 80px 80px 120px 80px;
  font-family: "Inter";
}
h1 {
  text-align: center;
  color: white;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 44px;
}
.Forth-data {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
}
.Forth-data p {
  color: rgb(96, 114, 131);
  margin: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 250px;
  max-width: 250px;
  min-height: 250px;
  min-width: 250px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 1000px;
  background-color: #fff;
  box-shadow: 7px 7px 150px 0 rgb(84 102 129 / 8%);
  letter-spacing: 1.5px;
}
.Forth-data img {
  width: 120px;
}
.Forth .data-counts {
  color: rgb(255, 114, 72);
  box-sizing: border-box;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 55px;
}
@media (max-width: 1200px) {
  .Forth {
    height: 100%;
    margin: 0;
    padding: 80px 0;
  }
  h1 {
    margin-bottom: 100px;
    text-align: center;
    padding: 0;
  }
  .Forth-data {
    flex-direction: column;
    height: 100%;
    margin-top: -25px;
  }
  .Forth-data img {
    transform: rotate(90deg);
    width: 90px;
  }
  .Forth-data p {
    margin: 50px 0;
  }
}
@media (min-width: 1400px) {
  .Forth {
    height: 100%;
    justify-content: center;
    padding: 150px 0;
  }
}
@media (max-width: 500px) {
  .Forth h1 {
    font-size: 36px;
  }
}
