.Header {
  height: 8vh;
  padding: 45px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.Header-outer {
  width: 100%;
  display: flex;
  padding: 0 40px;
  justify-content: center;
  align-items: center;
}
.content-box {
  width: 60vw;
  height: 100%;
  display: flex;
  align-items: center;
}
.nav-links {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.nav-links span {
  margin-right: 50px;
  white-space: nowrap;
  cursor: pointer;
  font-family: "Inter";
  letter-spacing: -0.25px;
  font-weight: 500;
}
.nav-links span:hover {
  color: rgb(84, 102, 129);
}
#logo {
  border-right: 1px dashed black;
  padding-right: 20px;
  margin-right: 80px;
}
#logo img {
  width: 200px;
}
#signup {
  width: 130px;
  height: 45px;
  border: 1px dashed grey;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 700;
  color: #6869ac;
  border-radius: 25px;
  background-color: white;
  outline: none;
}
#signup:hover {
  background-color: #282556;
  color: white;
  border: 1px solid #282556;
  transition: 0.5s;
}

.header-p {
  flex: 1;
}
.nav-btn {
  display: none;
  background-color: rgb(46, 62, 105);
  width: 42px;
  height: 42px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  border: none;
  outline: none;
}
.nav-btn img {
  width: 20px;
}

.navformobile {
  padding: 10px 0 40px 40px;
  width: 100%;
  height: 250px;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  background-color: white;
  z-index: 1;
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
}

.navformobile-links {
  display: flex;
  font-size: 17px;
  margin-top: 15px;
  width: 100%;
  justify-content: center;
}

@media (min-width: 1400px) {
  .Header div {
    width: 1100px;
  }
  .Header {
    height: 8vh;
  }
  .header-p {
    display: none;
  }

  .buttons {
    flex: 0;
  }
}
@media (max-width: 1020px) {
  .nav-links {
    display: none;
  }
  .nav-btn {
    display: flex;
  }
  .Header-outer {
    padding: 0 20px;
    height: 100%;
    justify-content: space-between;
  }

  .Header {
    padding: 20px 0 0 0;
    height: 100%;
  }
}
@media (max-width: 750px) {
  #signup {
    display: none;
  }
  .Header-outer {
    justify-content: space-between;
    width: 100vw;
  }
}

@media (max-width: 400px) {
  .Header {
    padding: 20px 0px;
  }
  #logo {
    margin-right: 0;
  }
  #logo img {
    width: 180px;
  }
}

@media (max-width: 330px) {
  .Header {
    padding: 40px 15px;
  }
  #logo img {
    width: 160px;
  }
  .nav-btn {
    width: 37px;
    height: 37px;
  }
}
