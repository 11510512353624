.Top {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  background-color: rgb(84, 102, 129);
  padding: 10px 40px;
  color: white;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-weight: bold;
  letter-spacing: 0.02em;
  box-sizing: border-box;
}
.Top button {
  color: #6667ab;
  border: 1px dashed #6667ab;
  background-color: rgba(250, 235, 215, 0);
  border-radius: 32px;
  padding: 6px 14px;
  margin-left: 15px;

  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;

  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}
.Top button:hover {
  background-color: #6667ab;
  color: white;
}

#remove {
  background-color: rgb(238, 236, 236);
  display: flex;
  height: 20px;
  width: 20px;
  padding-bottom: 0px;
  margin-left: 15px;

  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: rgb(92, 89, 89);
  font-size: 11px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  cursor: pointer;
}
@media (max-width: 560px) {
  /* .Top {
    display: none;
  } */
  .Top {
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  #empty-tag {
    display: none;
  }
  .Top button {
    width: 100px;
  }
  #top-text {
    display: flex;
    align-items: center;
    padding: 0;
    width: 100%;
  }
}
