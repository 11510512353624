.tnc {
  padding: 15px 20px;
  text-align: justify;
}
.tnc h1 {
  text-align: left;
  color: black;
  font-size: 18px;
  margin: 0;
}
.tnc ul {
  padding: 20px;
}
.heading {
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
  font-weight: bold;
  text-transform: uppercase;
}
