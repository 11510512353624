body {
  font-family: Arial, "Inter", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #484dff;
}

::-webkit-scrollbar-thumb:hover {
  background: #484dff;
}
