.Third {
  background-color: rgb(0, 22, 50);
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 100%;
  color: white;
  padding: 100px 0 130px 0;
}
.Third h1 {
  text-align: center;
  font-weight: bold;
  white-space: normal;
  color: white;
  line-height: 1;
  font-family: "Roboto", sans-serif;
  font-size: 48px;
}
#home-signIn {
  width: 530px;
  height: 55px;
  border-radius: 35px;
  border: none;
  display: flex;
  padding: 4px;
  justify-content: space-between;
  margin-top: 60px;
  background-color: white;
  overflow: hidden;
}
#home-signIn input {
  padding: 0 20px;
  border: none;
  font-weight: bold;
  width: 300px;
  outline: none;
  font-size: 14px;
  font-family: sans-serif;
  border: none;
}
#home-signIn input::placeholder {
  color: rgb(216, 203, 203);
}
#home-signIn button {
  background-color: orange;
  width: 220px;
  font-family: sans-serif;
  font-size: 14px;
  height: 100%;
  font-weight: bold;
  border-radius: 35px;
  border: none;
  color: white;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
#home-signIn button:hover {
  background-color: #282556;
}
@media (max-width: 1000px) {
  #home-signIn {
    width: 50%;
    border-radius: 35px;
  }

  #home-signIn button {
    width: 50%;
    font-size: 14px;
  }
}
@media (min-width: 1400px) {
  .Third {
    height: 100%;
    padding: 200px 0;
  }
}
@media (max-width: 770px) {
  .Third h1 {
    font-size: 2.5rem;
  }
  #home-signIn {
    width: 80%;
    border: 1px solid black;
  }
  #home-signIn input {
    width: 50%;
  }
  #home-signIn button {
    width: 50%;
  }
}

@media (max-width: 450px) {
  .Third h1 {
    font-size: 36px;
  }
  #home-signIn {
    width: 80%;
    height: 45px;
  }
  #home-signIn input {
    width: 50%;
    font-size: 12px;
  }
  #home-signIn button {
    width: 50%;
    font-size: 12px;
  }
}
