.Footer {
  background-color: rgb(31, 54, 86);
  padding: 40px 50px 60px 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  font-family: "Inter";
}
#HClogo {
  height: 50px;
}
.Footer-main {
  display: flex;
  justify-content: space-between;
}
.Footer-links {
  display: flex;
  justify-content: space-between;
  width: 550px;
  align-items: center;
  padding: 0 20px;
}
.Footer-links p {
  margin: 0 15px;
  font-size: 14px;
}
#insta-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
#insta-logo img {
  width: 37px;
  border: white 1px solid;
  background-color: white;
  padding: 4px;
  border-radius: 50%;
}
.address {
  width: 80%;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.363);
}
hr {
  width: 98%;
  border: 1px solid rgb(90, 88, 88);
  background-color: rgba(128, 128, 128, 0);
  margin-top: 50px;
}
#copyright {
  margin-top: 20px;
  font-size: 12px;
}
.Link {
  text-decoration: none;
  color: white;
}
@media (max-width: 1000px) {
  .Footer {
    align-items: center;
    height: 100%;
  }
  .Footer-main {
    flex-direction: column;
    align-items: center;
  }
  .Footer-main-logo-address {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Footer-links {
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  .Footer-links p {
    padding: 0;
    margin: 0;
  }
  .address {
    text-align: center;
    width: 100%;
  }
}
