.privacy {
  padding: 20px;
  text-align: left;
}
.hd1 {
  color: rgb(97, 96, 96);
  text-align: left;
  font-size: 30px;
  font-weight: 400;
  margin-top: 40px;
  font-family: "Inter";
  margin-bottom: -20px;
}

.para {
  color: rgb(97, 96, 96);
  text-align: justify;
  font-family: "Inter";
  font-size: 16px;
}

.hline {
  height: 1px;
  border: none;
  border-bottom: 1px solid darkgrey;
  margin-bottom: 30px;
}
.main_list {
  list-style: none;
}
ul {
  list-style: disc;
  margin-left: 40px;
}
.hd2 {
  font-weight: 500;
  color: rgb(68, 67, 67);
}
