.Home {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../public/background.svg");
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: contain;
  height: 100%;
}
.home-main {
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  padding: 25px 0px 100px 0px;
}
#home-heading {
  width: 62%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 30px 0 0 60px;
  box-sizing: border-box;
  height: 70vh;
  align-items: flex-start;
}
#home-heading span {
  color: rgb(22, 38, 83);
  font-weight: bold;
  white-space: normal;
  text-align: left;
  color: #1a3066;
  line-height: 1.2;
  letter-spacing: 2px;
  font-family: "Roboto", sans-serif;
  font-size: 55px;
}
#home-img {
  width: 38%;
  padding-top: 60px;
}
#home-img img {
  height: 600px;
}

#home-signin {
  width: 530px;
  height: 60px;
  border-radius: 35px;
  border: 1px solid rgb(216, 203, 203);
  display: flex;
  padding: 4px;
  justify-content: space-between;
  background-color: white;
  overflow: hidden;
  margin-top: 60px;
}
#home-signin input {
  border: none;
  font-weight: 600;
  font-family: sans-serif;
  width: 300px;
  font-size: 14px;
  outline: none;
  padding: 0 20px;
  flex: 1;
}
#home-signin input::placeholder {
  color: rgba(148, 147, 147, 0.729);
}
#home-signin button {
  background-color: orange;
  font-family: "Inter";
  width: 200px;
  height: 100%;
  font-weight: 700;
  border-radius: 35px;
  border: none;
  letter-spacing: 0.25px;
  color: white;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
#home-signin button:hover {
  background-color: #1a3066;
}
@media (min-width: 1400px) {
  .home-main {
    width: 1300px;
    padding: 0;
    margin-top: -50px;
  }
  #home-img {
    padding-top: 0px;
  }
  #home-signin button {
    width: 230px;
    font-size: 17px;
  }
  #home-signin input {
    font-size: 17px;
  }
}
@media (max-width: 1250px) {
  #home-img img {
    height: 530px;
  }
}
@media (max-width: 1020px) {
  .home-main {
    display: flex;
    flex-direction: column;
  }
  #home-heading {
    align-items: center;
    width: 100vw;
    justify-content: center;
    padding-left: 0px;
    text-align: center;
    padding: 70px 0 20px 0;
    height: 100%;
    align-items: center;
  }
  #home-heading span {
    margin: 0;
  }
  #home-img {
    width: 100vw;
    display: flex;
    margin-top: 0px;
    justify-content: center;
    align-items: center;
  }
  #home-img img {
    height: 600px;
  }
  #home-signin {
    height: 60px;
    margin-top: 60px;
  }

  #home-signin button {
    height: 100%;
  }
}

@media (max-width: 550px) {
  #home-heading span {
    text-align: center;
    align-items: center;
  }
  #home-signin {
    width: 350px;
    height: 54px;
  }
  #home-signin input {
    width: 150px;
  }

  #home-signin button {
    width: 80%;
    padding: 0 10px;
  }
  #home-img img {
    width: 80vw;
    height: auto;
    margin-top: 0px;
  }
}

@media (max-width: 455px) {
  #home-heading span {
    font-size: 38px;
    text-align: center;
    align-items: center;
  }
  #home-signin {
    width: 85%;
  }
}

@media (max-width: 375px) {
  #home-heading span {
    font-size: 2.2rem;
    text-align: center;
    align-items: center;
  }
  #home-signin {
    width: 85%;
    height: 45px;
  }
  #home-signin input {
    font-size: 12px;
  }
  #home-signin button {
    font-size: 12px;
    font-weight: 600;
  }
}
